import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Triangle from "../triangle/triangle"

class CaseStudyTeaserImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      url: this.props.url,
      image: this.props.image,
      title: this.props.title,
      location: this.props.location,
    };
  }

  render() {
    return (
      <Link to={`${this.state.url}/`} className={`block relative h-full transition ease-in-out duration-500 group text-white text-2xl md:text-2xl lg:text-3xl font-display leading-tight ${ this.props.classFilterKey }`}>
        <Img
          className="bg-grey"
          fluid={this.state.image}
        />
          <div className="absolute top-0 bottom-0 left-0 right-0 z-0 transition duration-500 ease-in-out opacity-0 bg-secondary-dark group-hover:opacity-75"></div>

          <div className="absolute top-0 right-0 p-6 transition duration-500 ease-in-out opacity-0 group-hover:opacity-100"><FontAwesomeIcon className="inline-block w-6 h-6 mb-1 mr-2" icon="map-marker" size="sm" />{ this.state.location }</div>


          <div className="absolute bottom-0 left-0 right-0 w-full pb-1 pl-6 pr-6 md:pb-0 group-hover:pb-6 teaser__inner">
            <div className="relative z-10 mb-1">
              { this.state.title }
            </div>
            <span className="block w-full text-base transition duration-500 ease-in-out opacity-0 group-hover:opacity-100">
              Read more
              <span className="inline-block ml-2">
                <Triangle size="small" />
              </span>
            </span>
          </div>
      </Link>
    );
  }
}

export default CaseStudyTeaserImage

CaseStudyTeaserImage.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  image: PropTypes.object,
  classFilterKey: PropTypes.string,
}

CaseStudyTeaserImage.defaultProps = {
  slug: ``,
  title: ``,
  location: ``,
  image: {},
  classFilterKey: ``,
}
